.embla {
  /* max-width: 48rem; */
  margin: auto;
  /* --slide-height: 19rem; */
  --slide-spacing: 1rem;
  --slide-size: 100%;
}
#serveCarousel .embla__viewport .embla__slide {
  flex: 0 0 100%;
}

#companiesCarousel .embla__viewport .embla__slide {
  flex: 0 0 22%;
  display: flex;
  justify-content: center;
  margin-inline: 1rem;
  /* img {
    padding: 3.75rem 3rem;
  } */
}

#servicesCarousel .embla__viewport .embla__slide {
  flex: 0 0 33.3%;
}

#casestudiesCarousel .embla__viewport .embla__slide {
  flex: 0 0 33.3%;
}

#scienceCarousel .embla__viewport .embla__slide {
  flex: 0 0 33.3%;
}

#aboutMastheadCarousel .embla__viewport .embla__slide {
  flex: 0 0 30%;
}

#matchingBenefitsCarousel .embla__viewport .embla__slide {
  flex: 0 0 100%;
}

#latestScienceCarousel .embla__viewport .embla__slide {
  flex: 0 0 25%;
}

@media screen and (max-width: 768px) {
  #companiesCarousel .embla__viewport .embla__slide {
    flex: 0 0 50%;
  }

  #servicesCarousel .embla__viewport .embla__slide {
    flex: 0 0 75%;
  }

  #casestudiesCarousel .embla__viewport .embla__slide {
    flex: 0 0 75%;
  }

  #scienceCarousel .embla__viewport .embla__slide {
    flex: 0 0 75%;
  }

  #aboutMastheadCarousel .embla__viewport .embla__slide {
    flex: 0 0 75%;
  }

  #latestScienceCarousel .embla__viewport .embla__slide {
    flex: 0 0 75%;
  }
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  cursor: grab;
  user-select: none;
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}

.serve-carousel-container, .benefits-carousel-container {
  .embla__dots {
    flex-direction: column;
    gap: .5rem;
    .embla__dot {
      height: .5rem;
      width: .5rem;
    }
    .embla__dot--selected {
      height: 1rem;
    }
    .embla__dot:after {
      @apply bg-purple-5;
      height: .5rem;
      width: .5rem;
    }
    .embla__dot--selected:after {
      background-color: #DED0EF;
      height: 1rem;
      border-radius: .25rem;
    }
  }
}

#aboutMastheadCarousel .embla__dots {
  /* justify-content: center; */
  gap: 1rem;
  margin-top: 1.5rem;
}
