@keyframes animate-top {
  0% {
    background: #3e3e3e; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #3e3e3e),
      color-stop(100%, #2b2b2b)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* IE10+ */
    background: linear-gradient(to bottom, #3e3e3e 0%, #2b2b2b 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3e3e', endColorstr='#2b2b2b',GradientType=0 );
  }

  100% {
    color: #474747;

    transform: translateX(0px) translateY(0.35em) translateZ(5px)
      rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  }
}

@keyframes animate-bottom {
  0% {
    color: #474747;
    opacity: 1;
  }

  100% {
    transform: translateX(0) translateY(0) translateZ(0) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg);

    opacity: 1;
  }
}

@-webkit-keyframes animate-top {
  0% {
    background: #3e3e3e; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #3e3e3e),
      color-stop(100%, #2b2b2b)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      #3e3e3e 0%,
      #2b2b2b 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #3e3e3e 0%, #2b2b2b 100%); /* IE10+ */
    background: linear-gradient(to bottom, #3e3e3e 0%, #2b2b2b 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3e3e', endColorstr='#2b2b2b',GradientType=0 );
  }

  100% {
    color: #474747;

    -webkit-transform: translateX(0px) translateY(0.35em) translateZ(5px)
      rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  }
}

@-webkit-keyframes animate-bottom {
  0% {
    color: #474747;
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0) translateY(0) translateZ(0) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg);

    opacity: 1;
  }
}

.number {
  position: relative;
  /* float: left; */
  /* margin-right: 5px; */

  font-size: 4em;

  width: 1em;
  height: 1.5em;
}

.number span {
  position: absolute;
  display: block;
  font-weight: 400;
  text-align: center;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  color: #773DBE;
  font-family: georgiapro, sans-serif;

  width: 1.3em;
  height: 1.5em;
  perspective: 7.14em;
}

.number span:before {
  display: block;
  height: 0.75em;
  overflow: hidden;

  line-height: 1.52em;

  border-bottom: 1px solid white;

  border-radius: 8px 8px 0 0;

  background-color: #F8F8F8;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3e3e', endColorstr='#2b2b2b',GradientType=0 ); /* IE6-9 */
}

.number span:after {
  display: block;
  height: 0.75em;
  overflow: hidden;
  line-height: 0;

  border-top: 1px solid white;

  border-radius: 0 0 8px 8px;

  transition: color 100ms, background-color 100ms;

  background-color: #F8F8F8;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#252525', endColorstr='#070707',GradientType=0 ); /* IE6-9 */
}

.flip .primary:before {
  animation: animate-top 250ms;
  -moz-animation-fill-mode: forwards;

  -webkit-animation: animate-top 250ms;
  -webkit-animation-fill-mode: forwards;

  background-color: #773DBE;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#303030',GradientType=0 ); /* IE6-9 */
}

.flip .primary:after {
  animation: animate-bottom 250ms;
  -moz-animation-fill-mode: forwards;
  animation-delay: 250ms;

  -webkit-animation: animate-bottom 250ms;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 250ms;
}

.primary:after {
  opacity: 0;

  transform: translateX(0) translateY(-0.28em) translateZ(0.35em) rotateX(91deg)
    rotateY(0deg) rotateZ(0deg);

  -webkit-transform: translateX(0) translateY(-0.28em) translateZ(0.35em)
    rotateX(91deg) rotateY(0deg) rotateZ(0deg);
}

.primary {
  z-index: 2;
}

.secondary {
  z-index: 1;
}

/* Number 0 */

[data-number="0"] span.primary:before {
  content: "0";
}

[data-number="0"] span.secondary:after {
  content: "0";
}

[data-number="0"] span.secondary:before {
  content: attr(title);
}

[data-number="0"] span.primary:after {
  content: attr(title);
}

/* Number 9 */

[data-number="9"] span.primary:before {
  content: "9";
}

[data-number="9"] span.secondary:after {
  content: "9";
}

[data-number="9"] span.secondary:before {
  content: attr(title);
}

[data-number="9"] span.primary:after {
  content: attr(title);
}

/* Number 8 */

[data-number="8"] span.primary:before {
  content: "8";
}

[data-number="8"] span.secondary:after {
  content: "8";
}

[data-number="8"] span.secondary:before {
  content: attr(title);
}

[data-number="8"] span.primary:after {
  content: attr(title);
}

/* Number 7 */

[data-number="7"] span.primary:before {
  content: "7";
}

[data-number="7"] span.secondary:after {
  content: "7";
}

[data-number="7"] span.secondary:before {
  content: attr(title);
}

[data-number="7"] span.primary:after {
  content: attr(title);
}

/* Number 6 */

[data-number="6"] span.primary:before {
  content: "6";
}

[data-number="6"] span.secondary:after {
  content: "6";
}

[data-number="6"] span.secondary:before {
  content: attr(title);
}

[data-number="6"] span.primary:after {
  content: attr(title);
}

/* Number 5 */

[data-number="5"] span.primary:before {
  content: attr(title);
}

[data-number="5"] span.secondary:after {
  content: attr(title);
}

[data-number="5"] span.secondary:before {
  content: attr(title);
}

[data-number="5"] span.primary:after {
  content: attr(title);
}

/* Number 4 */

[data-number="4"] span.primary:before {
  content: "4";
}

[data-number="4"] span.secondary:after {
  content: "4";
}

[data-number="4"] span.secondary:before {
  content: attr(title);
}

[data-number="4"] span.primary:after {
  content: attr(title);
}

/* Number 3 */

[data-number="3"] span.primary:before {
  content: attr(title);
}

[data-number="3"] span.secondary:after {
  content: attr(title);
}

[data-number="3"] span.secondary:before {
  content: attr(title);
}

[data-number="3"] span.primary:after {
  content: attr(title);
}

/* Number 2 */

[data-number="2"] span.primary:before {
  content: "2";
}

[data-number="2"] span.secondary:after {
  content: "2";
}

[data-number="2"] span.secondary:before {
  content: attr(title);
}

[data-number="2"] span.primary:after {
  content: attr(title);
}

/* Number 1 */

[data-number="1"] span.primary:before {
  content: "1";
}

[data-number="1"] span.secondary:after {
  content: "1";
}

[data-number="1"] span.secondary:before {
  content: attr(title);
}

[data-number="1"] span.primary:after {
  content: attr(title);
}
