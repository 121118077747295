@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @import "typography.css"; */
@import "reset.css";
@import "embla.css";
@import "counter.css";

/* loading custom fonts  */
@font-face {
  font-family: "Figtree";
  src: url("/public/fonts/figtree-variableFont_wght.ttf");
}

body {
  font-family: "Figtree", sans-serif;
  /* background-color: #FCF2FA; */
  /* overflow-y: visible;
  overflow-x: hidden; */
  overflow: visible;
}

@layer utilities {

  h1 {
    font-size: 4rem;
    font-family: georgiapro, sans-serif;
    font-weight: 400;
    line-height: 72px;
  }
  h2 {
    font-size: 3.5rem;
    font-family: georgiapro, sans-serif;
    font-weight: 400;
    line-height: 64px;
  }
  h3 {
    font-size: 2.75rem;
    font-family: georgiapro, sans-serif;
    font-weight: 400;
    line-height: 56px;
  }
  h4 {
    font-size: 2rem;
    font-family: georgiapro, sans-serif;
    font-weight: 400;
    line-height: 40px;
  }
  .b1 {
    font-size: 1.375rem;
    font-family: "Figtree", sans-serif;
    font-weight: 400;
    line-height: 30px;
  }
  .b2 {
    font-size: 1.125rem;
    font-family: "Figtree", sans-serif;
    font-weight: 400;
    line-height: 26px;
  }
  .b3 {
    font-size: 1rem;
    font-family: "Figtree", sans-serif;
    font-weight: 400;
    line-height: 24px;
  }
  .b4 {
    font-size: 0.75rem;
    font-family: "Figtree", sans-serif;
    font-weight: 400;
    line-height: 20px;
  }
  .button {
    font-size: 1.125rem;
    font-family: "Figtree", sans-serif;
    font-weight: 500;
    line-height: 26px;
  }
  .input-field {
    font-size: 1rem;
    font-family: "Figtree", sans-serif;
    font-weight: 500;
    line-height: 24px;
  }
  .caption {
    font-size: 0.5rem;
    font-family: "Figtree", sans-serif;
    font-weight: 500;
    line-height: 14px;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 3rem;
      font-family: georgiapro, sans-serif;
      font-weight: 400;
      line-height: 56px;
    }
    h2 {
      font-size: 2.5rem;
      font-family: georgiapro, sans-serif;
      font-weight: 400;
      line-height: 48px;
    }
    h3 {
      font-size: 2rem;
      font-family: georgiapro, sans-serif;
      font-weight: 400;
      line-height: 40px;
    }
    h4 {
      font-size: 1.5rem;
      font-family: georgiapro, sans-serif;
      font-weight: 400;
      line-height: 32px;
    }
    .b1 {
      font-size: 1.25rem;
      font-family: "Figtree", sans-serif;
      font-weight: 400;
      line-height: 28px;
    }
    .b2 {
      font-size: 1rem;
      font-family: "Figtree", sans-serif;
      font-weight: 400;
      line-height: 24px;
    }
    .b3 {
      font-size: 0.875rem;
      font-family: "Figtree", sans-serif;
      font-weight: 400;
      line-height: 22px;
    }
    .b4 {
      font-size: 0.625rem;
      font-family: "Figtree", sans-serif;
      font-weight: 400;
      line-height: 18px;
    }
    .mobile-button {
      font-size: 1rem;
      font-family: "Figtree", sans-serif;
      font-weight: 500;
      line-height: 24px;
    }
    .mobile-input-field {
      font-size: 1rem;
      font-family: "Figtree", sans-serif;
      font-weight: 500;
      line-height: 24px;
    }
    .mobile-caption {
      font-size: 0.5rem;
      font-family: "Figtree", sans-serif;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

#servicesCarousel .services-card {
  min-height: 30rem;
}

/*** Navigating Section ***/

#navigatingSection,
.key-features-section {
  overflow: hidden;
  position: relative;
  height: 100vh;
  .point {
    display: flex;
    height: 75vh;
    width: 100%;
  }
  .points-container {
    height: 75vh;
    position: relative;
  }
  .indicators {
    right: 0rem;
    top: 0;
    /* margin: 10vh 0; */
    height: 100%;
    width: 6px;
    display: none;
    flex-direction: column;
    justify-content: center;
    position: absolute;
  }

  .indicator {
    width: 100%;
    background-color: #adadad;
    /* flex-grow: 1; */
    margin: 2px 0;
    border-radius: 4px;
    height: 6px;
  }
}

/*** Menu ***/

/* .menu-horizontal > li:not(.menu-title) > details > ul{
  padding: 3rem 2rem;
} */

.menu :where(li ul):before {
  display: none;
}

.menu :where(li ul) {
  /* padding-left: 0; */
  margin-left: 0;
}

/* .menu :where(li ul.submenu-links){
  margin-left: auto;
} */

.menu :where(li:not(.menu-title) > *:not(ul, details, .menu-title, .btn)):hover,
.menu :where(li:not(.menu-title) > details > summary:not(.menu-title)):hover,
.menu li > *:not(ul, .menu-title, details, .btn):active,
.menu li > *:not(ul, .menu-title, details, .btn).active,
.menu li > details > summary:active {
  background: none;
}

.menu :where(li:not(.menu-title) .seperator) {
  @apply bg-purple-6 pointer-events-none;
  padding: 0;
}

.dropdown-hover .dropdown-content,
.dropdown:not(.dropdown-hover) .dropdown-content {
  top: 100%;
}

/*** Counter ***/

.counter {
  min-height: 7rem;
}

.container {
  padding-inline: 1rem;
  margin-inline: auto;
}

/*** input styles ***/

.custom-input {
  @apply bg-purple-2 text-purple-8 border-[1.5px] border-solid border-grey-2 hover:bg-purple-1 hover:text-purple-6 active:text-purple-6 focus:outline-none focus:bg-purple-1;

  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.input-icon {
  @apply absolute end-4 top-1/2 translate-y-[-60%];
}

/*** range slider ***/

.range-container output {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: -2px;
  height: 36px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: $secondary-900; */
  /* border: 1px solid $secondary-500; */
  z-index: 10;
}

.range-container .selected-range-wrapper {
  position: absolute;
  width: 100%;
  height: 5px;
  overflow: hidden;
  top: 13px;
  border-radius: 4px;
  pointer-events: none;
}
.range-container .selected-range-wrapper .selected-range {
  @apply bg-purple-5;
  position: absolute;
  width: inherit;
  height: inherit;
  right: 100%;
}
.range-container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  border-radius: 8px;
  /* overflow: hidden; */
}

.range-container input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 4px;
}

.range-container input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 36px;
  width: 60px;
  position: relative;
  bottom: 14px;
  /* background-color: pink; */
  cursor: pointer;
  z-index: 5;
}

.contact-for-demo {
  background-image: url("../assets/images/calculator/d-demo-bg.svg");
}

.calculator-masthead {
  background-image: url("../assets/images/calculator/d_bg.svg");
}

/*** customizing breadcrumbs ***/

.breadcrumbs > ul > li + *:before,
.breadcrumbs > ol > li + *:before {
  @apply text-purple-4;

  content: "/";
  transform: none;
  border: none;
  width: unset;
  height: unset;
  opacity: 1;
}

.breadcrumbs > ul > li > a,
.breadcrumbs > ol > li > a {
  @apply text-purple-4;

  content: "/";
  transform: none;
  border: none;
  width: unset;
  height: unset;
  opacity: 1;
}

.tableOfContents li.active {
  @apply text-purple-5;
}

/*** Custom Container ***/

.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 640px) {
  .custom-container {
    max-width: calc(100% - ((100% - 640px) / 2));
    margin-left: calc((100% - 640px) / 2);
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .custom-container {
    max-width: calc(100% - ((100% - 768px) / 2));
    margin-left: calc((100% - 768px) / 2);
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .custom-container {
    max-width: calc(100% - ((100% - 1024px) / 2));
    margin-left: calc((100% - 1024px) / 2);
    margin-right: 0;
  }
}

@media (min-width: 1280px) {
  .custom-container {
    max-width: calc(100% - ((100% - 1280px) / 2));
    margin-left: calc((100% - 1280px) / 2);
    margin-right: 0;
  }
}

@media (min-width: 1536px) {
  .custom-container {
    max-width: calc(100% - ((100% - 1536px) / 2));
    margin-left: calc((100% - 1536px) / 2);
    margin-right: 0;
  }
}

.swipe-section {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: visible;
  /* / */
}

.swipe-section .panel {
  position: absolute;
  border-radius: 50px 50px 0 0;
  width: 96%;
}

.content-wrapper {
  height: auto;
  /* min-height: 80vh; */
  /* background: black; */
  /* padding: 2rem 0; */
}

.content {
  /* width: 80%; */
  /* margin: 0 auto; */
  /* height: 70vh; */
  /* background-color: #333; */
  /* margin-bottom: 2rem; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* color: white; */
  /* font-size: 60px; */
  /* margin-top: 10vh !important; */
  /* background-color: white; */
}

.content:first-child{
  background-color: #fff;
}

.content:last-child {
  /* margin-bottom: 30vh; */
}

.science-masthead-container {
  background: linear-gradient(to bottom, #472472 50%, white 50%);
}

.science-blog-info-container {
  p:after {
    @apply text-purple-6;
    content: "|";
    transform: none;
    border: none;
    width: unset;
    height: unset;
    opacity: 1;
    margin-inline: 1rem;
  }
  div {
    &:last-child {
      p:after {
        content: "";
      }
    }
  }
}

.blog-post-body {
  &.science {
    blockquote {
      padding: 2rem 5rem;
      font-family: georgiapro, sans-serif;
      color: white;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
      border-radius: 16px;
      border: 2px solid var(--purple-01, #f8f5fb);
      background: var(--purple-08, #472472);
      position: relative;
      &:after {
        content: url("../assets/images/science/s_quote.svg");
        display: block;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }
    }
  }
  &.case-study {
    blockquote {
      @apply text-purple-5;
      /* @apply bg-purple-6; */
      margin-top: 1rem;
      padding: 2rem 3rem;
      font-family: georgiapro, sans-serif;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 56px;
      border-radius: 16px;
      border: 2px solid var(--purple-01, #f8f5fb);
      /* background: var(--purple-08, #472472); */
      position: relative;
      &:before {
        content: url("../assets/images/case-study/s_quote-1.svg");
        display: block;
        position: absolute;
        top: -1.5rem;
        left: 0;
      }
      &:after {
        content: url("../assets/images/case-study/s_quote-2.svg");
        display: block;
        position: absolute;
        bottom: -2rem;
        right: 1rem;
      }
    }
  }
}

/* #sidebar {
  width: 200px;
  height: 300px;
  position: absolute;
}

#header {
  height: 80px;
  margin-bottom: 10px;
}

#sidebar.fixed {
  position: fixed;
  top: 0;
}

#footer {
  height: 800px;
} */

/* .service-masthead{
  min-height: 80vh;
  display: flex;
  flex-direction: column
}

.service-masthead .custom-container{
  flex-grow: 1;
} */

.wrapper{
  @apply pt-28 md:pt-0;
}

.loader {
  --loader-size: 100px;
  width: var(--loader-size);
  height:var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #c4abe3 #c4abe3 transparent #c4abe3;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #773dbe #773dbe;
  width: calc(var(--loader-size) - 15px);
  height: calc(var(--loader-size) - 15px);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.7s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: calc(var(--loader-size) - 30px);
  height: calc(var(--loader-size) - 30px);
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 2s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.navbar .menu a:focus{
  @apply text-purple-3;
}


.Toastify__toast-container--top-right{
  top: 5rem !important;
}

/*** Blog ***/

.blog-post-body {
  h4 {
    color: #9061ca;
  }
  p {
    font-size: 1.125rem;
    font-family: "Figtree", sans-serif;
    font-weight: 400;
    line-height: 26px;
    color: #969696;
  }
}

.pagination-nav-btn,
.pagination-btn{
  @apply text-xs sm:text-base rounded-lg border border-grey-2 text-grey-5 bg-white hover:bg-grey-1 transition-all;
}

.previous.disabled .pagination-nav-btn,.next.disabled .pagination-nav-btn{
  @apply bg-grey-2;
}

.pagination-container li.selected a{
  @apply border-transparent bg-purple-5 text-white;
}

@media screen and (max-width: 1024px) {
  body {
    overflow: hidden !important;
  }
}

/*** Mobile Styles ***/

@import "mobile.css";
