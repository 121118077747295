@media screen and (max-width: 768px) {
  /* body {
    font-family: "Figtree", sans-serif;
    overflow: hidden !important;
  } */
  #servicesCarousel .services-card {
    min-height: 20rem !important;
  }

  /*** Counter ***/

  .number {
    position: relative;
    /* float: left; */
    /* margin-right: 5px; */

    font-size: 4.5em;

    width: 0.6em;
    height: 1.5em;
  }

  .number span {
    width: 0.8em;
    height: 1.5em;
    perspective: 7.14em;
  }

  /*** Blog ***/

  .science-masthead-container {
    background: linear-gradient(to bottom, #472472 30%, white 70%) !important;
  }

  blockquote {
    padding: 1rem 2rem !important;
    font-family: georgiapro, sans-serif;
    color: white;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px !important;
    border-radius: 16px;
    border: 2px solid var(--purple-01, #f8f5fb);
    background: var(--purple-08, #472472);
    position: relative;
    &:after {
      content: url("../assets/images/science/s_quote.svg");
      display: block;
      position: absolute;
      bottom: .5rem !important;
      right: .5rem !important;
    }
  }
}
